<template>
    <div class="work-role-item" :style="'background-image: url(' + cover_image.data.full_url + ')'" :class="isActive ? 'active': 'inactive'" v-on:click="clickRole()">
        <div class="role-info">
            <div class="content">
                <i class="ri-film-line" v-if="role_type.includes('synchron')"></i>
                <i class="ri-gamepad-line" v-if="role_type === 'videospiel'"></i>
                <i class="ri-shopping-cart-line" v-if="role_type === 'werbung'"></i>
                <i class="ri-book-2-line" v-if="role_type === 'hoerbuch'"></i>

                <span class="name">{{ role_name }}</span>
                <span class="work">{{ role_work }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WorkRoleItem',
        props: [
            'isActive',
            'role_direction',
            'role_name',
            'role_studio',
            'role_type',
            'role_work',
            'role_url',
            'cover_image',
        ],
        methods: {
            clickRole: function() {
                if(this.$props.role_url != null) {
                    window.open(this.$props.role_url, '_blank');
                }
            }
        }
    };
</script>

<style scoped lang="less">
    .work-role-item {
        background: rgba(255,255,255,0.1);
        overflow: hidden;
        position: relative;
        padding-top: 100%;
        aspect-ratio: 1/1;
        background-position: center;
        background-size: cover;

        & .role-info {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            padding: 25px;
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease-in-out all;

            & .content {
                display: flex;
                flex-direction: column;
                text-align: center;

                & i {
                    font-size: 32px;
                    margin-bottom: 20px;
                }
                & span {
                    display: inline-block;

                    &.name {
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                    &.work {
                        font-weight: 600;
                    }
                }
            }

            &:hover {
                background: rgba(0,0,0,0.6);
                backdrop-filter: blur(5px);
                opacity: 1;
                cursor: pointer;
            }
        }

        &.active {
            animation-name: go-to-active;
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
        }
        &.inactive {
            animation-name: go-to-inactive;
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
        }
    }

    @keyframes go-to-active {
        from {
            transform: scale(0);
            position: absolute;
        }
        to {
            transform: scale(1);
            position: relative;
        }
    }
    @keyframes go-to-inactive {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(0);
            position: absolute;
        }
    }
</style>
