<template>
    <div class="studio-specs" :class="isActive ? 'active' : ''" v-on:click.self="closeModal()">
        <div class="studio-specs-box">
            <div class="text">
                <div class="specs-header">Heimstudio Specs</div>
                <div v-html="details"></div>
            </div>
            <div class="sidebar" :style="'background-image: url(' + detailImage + ')'"></div>
            
            <div class="button" v-on:click="closeModal()">
                Schließen
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';

    export default {
        name: 'StudioSpecs',
        data: function() {
            return {
                isActive: false,
                isLoading: true,
                vfapi: null,
                detailImage: "",
                details: ""
            }
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            document.addEventListener('keyup', this.computeESCClose);

            // Global Modal Open Listener
            this.$root.$on('openStudioSpecs', () => {
                this.$data.isLoading = true;

                this.$data.vfapi.getItems('studio_specs', '*,detail_image.data.url').then(data => {
                    this.$data.detailImage = data[0].detail_image.data.full_url;
                    this.$data.details = data[0].details;

                    this.openModal();
                    this.$data.isLoading = false;
                });
            });
        },
        beforeDestroy: function() {
            document.removeEventListener('keyup', this.computeESCClose);
        },
        methods: {
            computeESCClose: function(e) {
                if(e.key == "Escape") {
                    this.closeModal();
                }
            },
            openModal: function() {
                this.$data.isActive = true;
            },
            closeModal: function() {
                this.$data.isActive = false;
            }
        }
    };
</script>

<style scoped lang="less">
    .studio-specs {
        background: rgba(0,0,0,0.7);
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        display: none;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out all;
        cursor: pointer;

        & .studio-specs-box {
            background: #1d1d1d;
            width: 90%;
            max-width: 800px;
            border-radius: 6px;
            cursor: default;
            display: grid;
            position: relative;
            grid-template-columns: auto 300px;

            & .text {
                padding: 25px;
                padding-bottom: 40px;

                & .specs-header {
                    font-size: 22px;
                    font-weight: 700;
                    position: absolute;
                    top: -35px;
                    left: 0px;
                }

                & p {
                    margin: 0;
                    margin-top: 15px;
                }
                & ul {
                    margin: 0;
                    margin-bottom: 15px;
                    margin-top: 5px;
                    padding: 0px;
                    list-style-type: none;

                    & li:before {
                        content: "–";
                        line-height: 1.5em;
                        font-weight: 600;
                        padding-right: 10px;
                    }
                }
            }
            & .sidebar {
                background-position: center;
                background-size: cover;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
            
            & .button {
                position: absolute;
                bottom: -13px;
                left: 50%;
                margin-left: -75px;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
                background: #fff;
                color: #222;
                padding: 8px 25px;
                border-radius: 100px;
                transform: skew(0deg, -2deg);
                transition: 0.2s ease-in-out all;

                &:hover {
                    opacity: 0.6;
                    cursor: pointer;
                }
                &:active {
                    opacity: 0.8;
                }
            }
        }
        &.active {
            display: flex;
            opacity: 1;
        }
    }

    @media screen and (max-width: 800px) {
        &.studio-specs {
            & .studio-specs-box {
                grid-template-columns: 1fr;
                overflow-y: auto;
                height: 90%;

                & .sidebar {
                    height: 200px;
                    margin-top: -50px;
                    margin-bottom: 25px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                & .button {
                    position: static;
                    width: auto;
                    justify-self: center;
                    margin-left: 0px;
                    margin-bottom: 15px;
                }
            }
        }
    }
</style>
