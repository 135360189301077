<template>
    <div class="companies">
        <img src="@/assets/img/crunchyroll_white.png" />
        <img src="@/assets/img/disneyplus_white.png" />
        <img src="@/assets/img/wakanim_white.png" /><br />
        <img src="@/assets/img/audible_white.png" />
        <img src="@/assets/img/sixt_white.png" />
    </div>
</template>

<script>
    export default {
        name: 'Companies'
    };
</script>

<style scoped lang="less">
    .companies {
        padding: 50px 0px;
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;

        & img {
            max-height: 60px;
            max-width: 80%;
            margin: 20px 30px;
            opacity: 0.4;
            transition: 0.5s ease-in-out opacity;
            
            &:hover {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: 800px) {
        .companies {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & img {
                display: block;
            }
        }
    }
</style>
