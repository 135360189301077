<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        mounted: function() {
            this.openContactModal =  function() {
                console.log("Poggers");
            };
        },
    };
</script>

<style lang="less">
    html {
        box-sizing: border-box;
        scroll-behavior: smooth;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body {
        padding: 0;
        margin: 0;
        min-height: 100vh;
        display: grid;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 14px;
    }
</style>
