<template>
    <div class="about">
        <div class="box box-aboutme">
            <img src="@/assets/img/avatar.png" />
            <p>Ich bin Vincent Fallow und arbeite seit 2019 als <strong>professioneller Sprecher</strong> im Bereich Synchron, Werbung, Hörbuch, Videospiel und Hörspiel.</p>
            <p>Den Anfang habe ich 2018 mit Videospielvertonung gemacht und mittlerweile habe ich mich auch als <strong>feste, fähige Stimme im Synchron- und Hörbuchbereich</strong> etabliert.</p>
            <p>Ich besitze ein sehr gut ausgestattetes <strong>Heimstudio</strong> und kann über SessionLink, SourceConnect, Skype, Zoom etc. <strong>auch von Zuhause</strong> zugeschaltet werden.</p>

            <ContactButton />
        </div>
        <div class="box box-aboutstudio">
            <div class="text">
                <strong>AUFNAHMEN VON ZU HAUSE</strong>
                <p>Für einen Tontechniker ist nichts besser, als trockene Sprachaufnahmen zu erhalten, die neutral klingen und eine gute Qualität beinhalten. Genau das biete ich jederzeit mit meinem Heimstudio. Retakes sowie ganze Aufnahmen können bei mir zu entsprechendem Preisaufschlag problemlos veranlasst werden.</p>
            </div>
            <div class="images">
                <img :src="image1" />
                <img :src="image2" />
                <img :src="image3" />
            </div>

            <StudioSpecsButton />
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';

    import ContactButton from '@/components/ContactForm/ContactButton.vue';
    import StudioSpecsButton from '@/components/StudioSpecs/StudioSpecsButton.vue';

    export default {
        name: 'About',
        data: function() {
            return {
                isLoading: true,
                vfapi: null,
                image1: "",
                image2: "",
                image3: ""
            }
        },
        components: {
            ContactButton,
            StudioSpecsButton,
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();
            this.$data.isLoading = true;

            this.$data.vfapi.getItems('studio_specs', 'image_1.data.url, image_2.data.url, image_3.data.url').then(data => {
                this.$data.image1 = data[0].image_1.data.full_url;
                this.$data.image2 = data[0].image_2.data.full_url;
                this.$data.image3 = data[0].image_3.data.full_url;
            });
        }
    };
</script>

<style scoped lang="less">
    .about {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 20px;
        padding: 50px 0px;
        padding-top: 25px;
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 95px;

        & .box {
            background: #1D1D1D;
            border-radius: 6px;

            &.box-aboutme {
                display: flex;
                flex-direction: column;
                padding: 25px 25px 45px;
                position: relative;

                & img {
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                    display: block;
                    position: relative;
                    align-self: center;
                    margin-bottom: 20px;
                    margin-top: -70px;
                    z-index: 2;
                }
                & p {
                    margin-top: 5px;
                    line-height: 1.75em;
                    color: rgba(255,255,255,0.6);

                    & strong {
                        color: #fff;
                        font-weight: 600;
                    }
                }
                & .button {
                    position: absolute;
                    bottom: -13px;
                    align-self: center;
                    transform: skew(0deg, -2deg);
                }
            }
            &.box-aboutstudio {
                display: grid;
                grid-template-rows: auto 1fr;
                padding: 25px;
                padding-bottom: 45px;
                position: relative;

                & .text {
                    & strong {
                        letter-spacing: 0.15em;
                    }
                    & p {
                        opacity: 0.6;
                        line-height: 1.5em;
                    }
                }
                & .images {
                    margin-top: 10px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 10px;

                    & img {
                        max-width: 100%;
                    }
                }
                & .button {
                    position: absolute;
                    bottom: -13px;
                    left: 50%;
                    margin-left: -130px;
                    transform: skew(0deg, -2deg);
                }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .about {
            grid-template-columns: 1fr 2fr;
            width: 95vw;
        }
    }

    @media screen and (max-width: 1000px) {
        .about {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media screen and (max-width: 800px) {
        .about {
            grid-template-columns: 1fr;
        }
    }
</style>
