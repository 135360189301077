<template>
    <div class="button" v-on:click="openStudioSpecsModal()">
        Specs zum Heimstudio
    </div>
</template>

<script>
    export default {
        name: 'StudioSpecsButton',
        methods: {
            openStudioSpecsModal: function() {
                this.$root.$emit('openStudioSpecs');
            }
        }
    };
</script>

<style scoped lang="less">
    .button {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        background: #fff;
        color: #222;
        padding: 8px 25px;
        border-radius: 100px;
        transition: 0.2s ease-in-out all;

        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }
        &:active {
            opacity: 0.8;
        }
    }
</style>
