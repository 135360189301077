import Vue from 'vue';
import 'remixicon/fonts/remixicon.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.use(require('vue-cookies'));
Vue.$cookies.config('30d');

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
