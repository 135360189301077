<template>
    <footer>
        <span>&copy; 2020 &dash; Vincent Fallow &dash; Voice Over</span>

        <router-link :to="{ name: 'LegalInformation' }">Impressum & Datenschutz</router-link>

        <ContactButton />
    </footer>
</template>

<script>
    import ContactButton from '@/components/ContactForm/ContactButton.vue';

    export default {
        name: 'Footer',
        components: {
            ContactButton,
        },
    };
</script>

<style scoped lang="less">
    footer {
        padding: 50px;
        background: #000;
        text-align: center;

        & span {
            font-size: 18px;
            display: block;
            margin-bottom: 5px;
        }
        & a {
            color: rgba(255,255,255,0.4);
            font-size: 12px;
            text-decoration: none;
            display: block;
            margin-bottom: 20px;
            transition: 0.15s ease color;

            &:hover {
                color: #fff;
            }
        }
        & .button {
            display: inline-block;
            padding: 7px 50px;
            color: #fff;
            background: transparent;
            border: 2px solid rgba(255,255,255,0.4);
        }
    }
</style>
