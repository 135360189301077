<template>
    <div class="work-roles">
        <div class="work-roles-selection">
            <div class="selection-item" :class="allTypesSelected ? 'active': ''" v-on:click="selectAll()">
                 <span>Alle</span>
            </div>

            <div
                v-for="(filter, n) in filters"
                :key="n"
                class="selection-item"
                :class="currentSelectedType === filter.type ? 'active': ''"
                @click="selectType(filter.type)"
            >
                <span>{{ filter.label }}</span>
            </div>
        </div>

        <div class="work-roles-list">
            <WorkRoleItem
                v-for="workRole in allWorkRoles"
                v-bind="workRole"
                :key="workRole.id"
                :isActive="currentSelectedType === workRole.role_type || allTypesSelected"
            />
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';

    import WorkRoleItem from '@/components/WorkRoles/WorkRoleItem.vue';

    export default {
        name: 'NewsItem',
        data: function() {
            return {
                filters: [
                    { type: 'synchron_anime', label: 'Anime (Synchron)' },
                    { type: 'synchron_cartoon', label: 'Cartoon (Synchron)' },
                    { type: 'synchron_realfilm', label: 'Realfilm (Synchron)' },
                    { type: 'synchron_realserie', label: 'Realserie (Synchron)' },
                    { type: 'videospiel', label: 'Videospiel' },
                    { type: 'werbung', label: 'Werbung' },
                    { type: 'hoerbuch', label: 'Hörbuch' },
                ],
                vfapi: null,
                allWorkRoles: [],
                allTypesSelected: true,
                currentSelectedType: ""
            }
        },
        components: {
            WorkRoleItem,
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            this.$data.vfapi.getItems('work_role', '*,cover_image.data.url').then(data => {
                this.$data.allWorkRoles = data;
                this.$data.allTypesSelected = true;
                this.$data.currentSelectedType = "";
            });
        },
        methods: {
            selectAll: function() {
                this.$data.allTypesSelected = true;
                this.$data.currentSelectedType = "";
            },
            selectType: function(newType) {
                this.$data.allTypesSelected = false;
                this.$data.currentSelectedType = newType;
            }
        }
    };
</script>

<style scoped lang="less">
    .work-roles {
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-gap: 20px;
        padding: 25px 0 50px;
        max-width: 1200px;
        margin: 0 auto;

        & .work-roles-selection {
            display: flex;
            flex-direction: column;
            gap: 10px;

            & .selection-item {
                padding: 10px 25px;
                border-radius: 100px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.1em;
                transform: skew(0deg, -2deg);
                transition: 0.15s ease-in-out all;

                &.active {
                    background: rgba(255,255,255,0.17);
                }
                &:hover {
                    background: rgba(255,255,255,0.07);
                    cursor: pointer;
                }
            }
        }
        & .work-roles-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    @media screen and (max-width: 1250px) {
        .work-roles {
            width: 95vw;
            grid-template-columns: 1fr;

            & .work-roles-selection {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
            }

            & .work-roles-list {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .work-roles {
            & .work-roles-list {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
</style>
