<template>
    <a href="mailto:contact@vincentfallow.com" class="button">
        Kontakt
    </a>
</template>

<script>
    export default {
        name: 'ContactButton',
        methods: {
        }
    };
</script>

<style scoped lang="less">
    .button {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        background: #fff;
        color: #222;
        padding: 8px 25px;
        border-radius: 100px;
        transition: 0.2s ease-in-out all;
        text-decoration: none;

        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }
        &:active {
            opacity: 0.8;
        }
    }

    @media screen and (max-width: 700px) {
        & .button {
            padding: 4px 10px;
        }
    }
</style>
