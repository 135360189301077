<template>
    <div class="content-index">
        <div class="hero-header" :style="'background-position: left ' + parallaxPosition + 'px'">
            <div class="claim" v-if="windowWidth > 1250">Stimm-<br />schauspieler</div>
            <AudioDemoPlayer v-if="windowWidth > 1250" />
        </div>
        <div class="claim" v-if="windowWidth < 1250">Stimm-<br />schauspieler</div>
        <AudioDemoPlayer v-if="windowWidth < 1250" />

        <NewsItems />

        <Features />

        <WorkRoles />

        <About />

        <Companies />
    </div>
</template>

<script>
    import VFAPI from '@/modules/module.api.js';

    import AudioDemoPlayer from '@/components/AudioDemoPlayer/AudioDemoPlayer.vue';
    import NewsItems from '@/components/NewsItems/NewsItems.vue';
    import Features from '@/components/Features/Features.vue';
    import WorkRoles from '@/components/WorkRoles/WorkRoles.vue';
    import About from '@/components/About/About.vue';
    import Companies from '@/components/Companies/Companies.vue';

    export default {
        name: 'Index',
        data: function() {
            return {
                windowWidth: window.innerWidth,
                parallaxPosition: 0,
                vfapi: null,
            }
        },
        components: {
            AudioDemoPlayer,
            NewsItems,
            Features,
            WorkRoles,
            About,
            Companies,
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();
        },
        created: function() {
            window.addEventListener('resize', this.onResize);
            window.addEventListener('scroll', this.onScroll);
        },
        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
            window.removeEventListener('scroll', this.onScroll); 
        },
        methods: {
            onResize() {
                this.$data.windowWidth = window.innerWidth
            },
            onScroll() {
                this.$data.parallaxPosition = window.scrollY / 4;
            }
        },
    };
</script>

<style scoped lang="less">
    .hero-header {
        background: url('../assets/img/hero-background.jpg');
        background-size: cover;
        width: 100%;
        height: 100vh;
        position: relative;
        z-index: 0;
    }

    .claim {
        position: absolute;
        bottom: 475px;
        right: 75px;
        font-size: 70px;
        text-shadow: 0px 6px 24px rgba(0,0,0,0.6);
        line-height: 1;
        font-weight: bold;
        text-align: right;
    }

    @media screen and (max-width: 1250px) {
        .hero-header {
            height: 90vh;
        }

        .claim {
            position: relative;
            text-align: center;
            bottom: inherit;
            right: inherit;
            margin-top: -70px;
            z-index: 100;
        }
    }

    @media screen and (max-width: 600px) {
        .hero-header {
            background-position: -200px 0px !important;
        }
        .claim {
            font-size: 50px;
        }
    }

    @media screen and (max-width: 360px) {
        .claim {
            font-size: 40px;
        }
    }
</style>